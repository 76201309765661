import Stars from "./Stars";
import "./Main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

const Main = (props) => {
    return (
        <Stars isMoving={true}>
            <div className="mainContent">
                <h1>Rene Pot</h1>
                <p>I'm a Senior Developer Advocate</p>
                <p className="subtext">
                    Open for advisory roles, permanent and freelance work.
                    <br />
                    Experienced in Technical Writing, Community Management, JavaScript and more.
                </p>
                <p>
                    I'm the founder of{" "}
                    <a href="https://brewscout.coffee" target="_blank">
                        BrewScout
                    </a>
                </p>

                <div className="socials">
                    <a href="https://github.com/Topener" target="_blank">
                        <FontAwesomeIcon icon={brands("github")} />
                    </a>
                    <a href="https://bsky.app/profile/renepot.net" target="_blank">
                        <img src="/img/bsky.svg" width="48" height="48" alt="BSky Icon" className="svg-icon" />
                    </a>
                    <a href="https://www.linkedin.com/in/wraldpyk/" target="_blank">
                        <FontAwesomeIcon icon={brands("linkedin")} />
                    </a>
                    <a href="https://blog.renepot.net" target="_blank">
                        <FontAwesomeIcon icon={brands("medium")} />
                    </a>
                    <a href="https://mastodon.social/@wraldpyk" target="_blank" rel="me">
                        <FontAwesomeIcon icon={brands("mastodon")} />
                    </a>
                    <a href="https://twitter.com/Wraldpyk" target="_blank">
                        <FontAwesomeIcon icon={brands("twitter")} />
                    </a>
                    <a href="https://steamcommunity.com/id/wraldpyk/" target="_blank">
                        <FontAwesomeIcon icon={brands("steam")} />
                    </a>
                </div>

                <div className="legal-links">
                    renepot.net is the official website of Topener, registered with Chamber of Commerce in the Netherlands with number
                    84191627. <br />
                    BrewScout is a subsidiary of Topener, and registered under the same number.
                </div>
            </div>
        </Stars>
    );
};

export default Main;
